import styled from 'styled-components';
import { ReactComponent as InstagramSvg } from '@assets/icons/instagram.svg';
import { ReactComponent as HeadphonesSvg } from '@assets/icons/headphones.svg';
import { ReactComponent as LikesSvg } from '@assets/icons/likes.svg';
import { ReactComponent as CommentsSvg } from '@assets/icons/comments.svg';
import { ReactComponent as ThankYouSvg } from '@assets/icons/thank-you-icon.svg';
import { ReactComponent as ThankYouErrorSvg } from '@assets/icons/thank-you-icon-error.svg';
import { ReactComponent as CheckmarkSvg } from '@assets/icons/checkmark.svg';
import { ReactComponent as FacebookSvg } from '@assets/icons/facebook.svg';

export const InstagramIcon = styled(InstagramSvg)``;
export const FacebookIcon = styled(FacebookSvg)``;
export const HeadphonesIcon = styled(HeadphonesSvg)``;
export const LikesIcon = styled(LikesSvg)``;
export const CommentsIcon = styled(CommentsSvg)``;
export const ThankYouIcon = styled(ThankYouSvg)``;
export const ThankYouErrorIcon = styled(ThankYouErrorSvg)``;
export const CheckmarkIcon = styled(CheckmarkSvg)``;
