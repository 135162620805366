import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { flex } from '@utils';
import animationOn from '@assets/animations/checkbox-on.json';
import animationOff from '@assets/animations/checkbox-off.json';

const animationOnOptions = {
  autoplay: true,
  loop: false,
  animationData: animationOn,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const animationOffOptions = {
  autoplay: true,
  loop: false,
  animationData: animationOff,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CustomCheckbox = ({ name, children, value, onChange }) => (
  <Container>
    <input name={name} checked={value} onChange={onChange} type="checkbox" />
    {value ? (
      <Lottie options={animationOnOptions} width={24} height={24} style={{ minWidth: 24, margin: '0px 8px 0px 0px' }} />
    ) : (
      <Lottie
        options={animationOffOptions}
        width={24}
        height={24}
        style={{ minWidth: 24, margin: '0px 8px 0px 0px' }}
      />
    )}
    {children}
  </Container>
);

export default CustomCheckbox;

const Container = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  ${flex.alignCenter};

  input {
    display: none;
  }

  .checkmark {
    background: transparent;
    border: 2px solid #fff;
  }

  input:checked + .checkmark:after {
    content: '';
    position: absolute;
    height: 5.5px;
    width: 10px;
    top: 0;
    left: 0;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
