import React from "react";
import styled from "styled-components";
import { useSpring, animated as a } from "react-spring";
import { flex } from "@utils";
import { CommentsIcon, LikesIcon, HeadphonesIcon } from "@icons";

const AnimatedCounter = ({ value, isVisible, delay }) => {
  const animProps = useSpring({
    from: { val: 0 },
    to: { val: isVisible && value ? value : 0 },
    config: { duration: 3000 },
    delay: delay + 100,
  });
  return <a.span>{animProps.val.interpolate(val => Math.floor(val))}</a.span>;
};

const MusicCard = ({
  mobile,
  likes,
  listeners,
  comments,
  avatar,
  miniAvatar,
  musicType,
  distributor,
  label,
  name,
  author,
  animated,
  isVisible,
  animationDuration,
}) => {
  return (
    <Container className={`music-card ${isVisible ? "_visible" : ""}`}>
      <Header>
        <Avatar mobile={mobile}>
          <img src={avatar} alt={label} />
        </Avatar>
        <Column>
          <GreyText>{distributor}</GreyText>
          <BoldText>{label}</BoldText>
          <BlueText>{musicType}</BlueText>
        </Column>
      </Header>
      <Counters>
        <Counter mobile={mobile}>
          <HeadphonesIcon />
          <Value>
            {animated && isVisible ? (
              <AnimatedCounter
                value={listeners}
                isVisible={isVisible}
                delay={animationDuration}
              />
            ) : (
              listeners
            )}
          </Value>
        </Counter>
        {comments && (
          <Counter>
            <CommentsIcon />
            <Value>
              {animated && isVisible ? (
                <AnimatedCounter
                  value={comments}
                  isVisible={isVisible}
                  delay={animationDuration}
                />
              ) : (
                comments
              )}
            </Value>
          </Counter>
        )}
        <Counter>
          <LikesIcon />
          <Value>
            {animated && isVisible ? (
              <AnimatedCounter
                value={likes}
                isVisible={isVisible}
                delay={animationDuration}
              />
            ) : (
              likes
            )}
          </Value>
        </Counter>
      </Counters>
      <Footer>
        <MiniAvatar>
          <img src={miniAvatar} alt={name} />
        </MiniAvatar>
        <Column>
          <AuthorName>{name}</AuthorName>
          <GreyText>{author}</GreyText>
        </Column>
      </Footer>
    </Container>
  );
};

export default MusicCard;

const Container = styled.div`
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  overflow: hidden;
  background: #fff;
`;

const Header = styled.div`
  ${flex.alignCenter};
  height: 120px;
  padding-left: 24px;
`;

const Avatar = styled.div`
  width: ${p => (p.mobile ? "56px" : "72px")}
  height: ${p => (p.mobile ? "56px" : "72px")}
  border-radius: 50%;
  margin-right: 16px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const MiniAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Counters = styled.ul`
  display: flex;
  height: 48px;
  background: #fff;
  border: 1px solid #ededed;
`;

const Counter = styled.li`
  border-right: 1px solid #ededed;
  flex-basis: ${p => (p.mobile ? "50%" : "33.33%")};
  ${flex.center};

  &:last-child {
    border-right: none;
  }
`;

const Value = styled.span`
  margin-left: 15px;
  font-weight: 500;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const GreyText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  opacity: 0.6;
`;

const BoldText = styled.span`
  font-size: 24px;
  line-height: 32px;
  color: #1b1b1b;
  font-weight: 700;
`;

const BlueText = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #0058dc;
`;

const AuthorName = styled.span`
  font-weight: 500;
`;

const Footer = styled.div`
  background: #fafafa;
  padding: 16px 24px;
  height: 80px;
  ${flex.alignCenter};
`;
