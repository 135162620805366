import { css } from 'styled-components';

export const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const spaceBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
