import React from 'react';
import styled from 'styled-components';
import { flex } from '@utils';
import PropagateLoader from 'react-spinners/PropagateLoader';

const PageLoader = () => (
  <Center>
    <PropagateLoader color='#0058DC' size={15} />
  </Center>
);

export default PageLoader;

const Center = styled.div`
  ${flex.center};
  background: #fcfbfa;
  height: calc(100vh - (80px + 281px));
  width: 100%;
`;
