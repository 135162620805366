import React from 'react';
import styled from 'styled-components';
import { flex } from '@utils';
import { ReactComponent as AppStore } from '@assets/images/app-store.svg';
import { ReactComponent as AppStoreW } from '@assets/images/app-store-w.svg';
import { ReactComponent as AppStoreColumn } from '@assets/images/app-store-column.svg';
import { ReactComponent as AppStoreColumnW } from '@assets/images/app-store-column-w.svg';
import { ReactComponent as GooglePlay } from '@assets/images/google-play.svg';
import { ReactComponent as GooglePlayW } from '@assets/images/google-play-w.svg';
import { ReactComponent as GooglePlayColumn } from '@assets/images/google-play-column.svg';
import { ReactComponent as GooglePlayColumnW } from '@assets/images/google-play-column-w.svg';
import ReactPixel from 'react-facebook-pixel';

const trackDownload = () => ReactPixel.trackCustom('Download Event');

const Button = styled.button`
  width: ${(p) => p.width || '186px'};
  height: 48px;
  ${flex.center};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: #0058dc;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.2s all ease-in-out;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background: #004cbe;
  }
`;

export default Button;

export const FlexDownloadLinks = ({ white }) => (
  <FlexButtons>
    {white ? (
      <>
        <WrapperWithMargin
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
          }}
          onClick={trackDownload}
          href='https://apps.apple.com/us/app/id1489691722'
        >
          <AppStoreW className='app-store-btn' />
        </WrapperWithMargin>
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
          }}
          onClick={trackDownload}
          href='https://play.google.com/store/apps/details?id=pro.sweetcode.edmradio'
        >
          <GooglePlayW className='google-play-btn' />
        </a>
      </>
    ) : (
      <>
        <WrapperWithMargin
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
          }}
          onClick={trackDownload}
          href='https://apps.apple.com/us/app/id1489691722'
        >
          <AppStore className='app-store-btn' />
        </WrapperWithMargin>

        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
          }}
          onClick={trackDownload}
          href='https://play.google.com/store/apps/details?id=pro.sweetcode.edmradio'
        >
          <GooglePlay className='google-play-btn' />
        </a>
      </>
    )}
  </FlexButtons>
);

export const ColumnDownloadLinks = ({ white }) => (
  <ColumnButtons>
    {white ? (
      <>
        <WrapperWithMargin
          target='_blank'
          rel='noopener noreferrer'
          href='https://apps.apple.com/us/app/id1489691722'
          onClick={trackDownload}
        >
          <WhiteBackground>
            <AppStoreColumnW />
          </WhiteBackground>
        </WrapperWithMargin>
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={trackDownload}
          href='https://play.google.com/store/apps/details?id=pro.sweetcode.edmradio'
        >
          <WhiteBackground>
            <GooglePlayColumnW />
          </WhiteBackground>
        </a>
      </>
    ) : (
      <>
        <WrapperWithMargin
          target='_blank'
          rel='noopener noreferrer'
          href='https://apps.apple.com/us/app/id1489691722'
          onClick={trackDownload}
        >
          <BlackBackground>
            <AppStoreColumn />
          </BlackBackground>
        </WrapperWithMargin>
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={trackDownload}
          href='https://play.google.com/store/apps/details?id=pro.sweetcode.edmradio'
        >
          <BlackBackground>
            <GooglePlayColumn />
          </BlackBackground>
        </a>
      </>
    )}
  </ColumnButtons>
);

const FlexButtons = styled.div`
  display: flex;
  justify-self: flex-start;
  align-items: center;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 701px) {
    display: none;
  }
`;

const ColumnButtons = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media (max-width: 600px) {
    width: 70%;
  }

  @media (max-width: 700px) {
    display: flex;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const WrapperWithMargin = styled.a`
  width: 100%;
  margin-right: 24px;

  @media (max-width: 950px) {
    margin-right: 0px;
    margin-bottom: 24px;
  }
`;

const BlackBackground = styled.div`
  ${flex.center};
  background: #1b1b1b;
  border-radius: 4px;
  width: 100%;
  height: 48px;
`;

const WhiteBackground = styled.div`
  ${flex.center};
  background: #fff;
  border-radius: 4px;
  width: 100%;
  height: 48px;
`;
