import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CommonLayout from "@components/CommonLayout";
import { PageLoader } from "@common";

import ReactPixel from "react-facebook-pixel";

const Home = lazy(() => import("@pages/Home"));
const About = lazy(() => import("@pages/About"));
const ContactUs = lazy(() => import("@pages/ContactUs"));
const MobileApp = lazy(() => import("@pages/MobileApp"));
const RisingStar = lazy(() => import("@pages/RisingStar"));
const DjPanel = lazy(() => import("@pages/DjPanel"));
const Privacy = lazy(() => import("@pages/Privacy"));
const TermsAndConditions = lazy(() => import("@pages/TermsAndConditions"));
const TermsAndConditionsStreams = lazy(() =>
  import("@pages/TermsAndConditionsStreams")
);
const NotFound = lazy(() => import("@pages/NotFound"));

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init("181396386942989", {}, options);
ReactPixel.pageView();

const App = () => (
  <CommonLayout>
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/dj-panel" component={DjPanel} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/mobile-app" component={MobileApp} />
        <Route exact path="/rising-star" component={RisingStar} />
        <Route exact path="/privacy" component={Privacy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route
          exact
          path="/terms-and-conditions-streams"
          component={TermsAndConditionsStreams}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </CommonLayout>
);

export default App;
