import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { InstagramIcon, FacebookIcon } from '@icons';
import { ReactComponent as HeaderLogo } from '@assets/icons/header-logo.svg';
import { ReactComponent as FooterLogo } from '@assets/icons/footer-logo.svg';
import MobileBg from '@assets/images/mobile-bg.png';
import { use100vh } from 'react-div-100vh';
import { flex } from '@utils';
import { BurgerMenu, Center } from '@common';

const CommonLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const height = use100vh();
  const fullScreenHeight = height - 68;
  const toggle = () => setOpen((o) => !o);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = null;
  }, [isOpen]);

  return (
    <Container>
      <Sticky>
        <Padding>
          <Center>
            <Header>
              <HeaderLogo
                onClick={() => {
                  history.push('/');
                  setOpen(false);
                }}
                style={{ flexShrink: 0, cursor: 'pointer' }}
              />
              <FlexCenter>
                <NavLinksWrapper>
                  <SNavLink
                    exact
                    activeClassName='active-link'
                    to='/mobile-app'
                  >
                    Mobile app
                  </SNavLink>
                  <SNavLink
                    exact
                    activeClassName='active-link'
                    to='/rising-star'
                  >
                    Rising star
                  </SNavLink>
                  <SNavLink exact activeClassName='active-link' to='/dj-panel'>
                    DJ Panel
                  </SNavLink>
                  <SNavLink exact activeClassName='active-link' to='/about'>
                    About
                  </SNavLink>
                  <SNavLink
                    exact
                    activeClassName='active-link'
                    to='/contact-us'
                  >
                    Contact Us
                  </SNavLink>
                </NavLinksWrapper>
                <Link
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dj.edmradio.me'
                >
                  Radio Login
                </Link>
                <BurgerMenuWrapper>
                  <BurgerMenu isOpen={isOpen} toggle={toggle} />
                </BurgerMenuWrapper>
              </FlexCenter>
            </Header>
          </Center>
          <Menu
            fullScreenHeight={fullScreenHeight}
            onClick={toggle}
            isOpen={isOpen}
          >
            <MenuLink exact activeClassName='active-menu-link' to='/mobile-app'>
              Mobile app
            </MenuLink>
            <MenuLink
              exact
              activeClassName='active-menu-link'
              to='/rising-star'
            >
              Rising star
            </MenuLink>
            <MenuLink exact activeClassName='active-menu-link' to='/dj-panel'>
              DJ Panel
            </MenuLink>
            <MenuLink exact activeClassName='active-menu-link' to='/about'>
              About
            </MenuLink>
            <MenuLink exact activeClassName='active-menu-link' to='/contact-us'>
              Contact Us
            </MenuLink>
            <MobileLinkWrapper>
              <MobileRadioLogin
                target='_blank'
                rel='noopener noreferrer'
                href='https://dj.edmradio.me'
              >
                Radio Login
              </MobileRadioLogin>
            </MobileLinkWrapper>
          </Menu>
        </Padding>
      </Sticky>

      <Content>{children}</Content>

      <DesktopTabletFooter>
        <PaddingFooter>
          <Center>
            <FooterLinks>
              <FooterLogo
                style={{
                  alignSelf: 'center',
                }}
              />

              <LinkColumn>
                <SNavLink exact activeClassName='active-link' to='/mobile-app'>
                  Mobile app
                </SNavLink>
                <SNavLink exact activeClassName='active-link' to='/rising-star'>
                  Rising star
                </SNavLink>
              </LinkColumn>

              <LinkColumn>
                <SNavLink exact activeClassName='active-link' to='/dj-panel'>
                  DJ Panel
                </SNavLink>

                <SNavLink exact activeClassName='active-link' to='/privacy'>
                  Privacy Policy
                </SNavLink>
              </LinkColumn>

              <LinkColumn>
                <SNavLink exact activeClassName='active-link' to='/about'>
                  About
                </SNavLink>
                <SNavLink
                  exact
                  activeClassName='active-link'
                  to='/terms-and-conditions'
                >
                  Terms &#38; Conditions
                </SNavLink>
              </LinkColumn>

              <LinkColumn>
                <SNavLink exact activeClassName='active-link' to='/contact-us'>
                  Contact Us
                </SNavLink>
                <RadioLoginBtn
                  style={{
                    width: '150px',
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dj.edmradio.me'
                >
                  Radio Login
                </RadioLoginBtn>
              </LinkColumn>
            </FooterLinks>
          </Center>
        </PaddingFooter>

        <PaddingFooter>
          <Center>
            <CopyRight>
              <span>Copyright © 2020 Sweetcode Lab Inc.</span>

              <Empty />

              <FlexColumn>
                <span>Send your demos to</span>
                <OuterLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='mailto:demo@edmradio.me'
                >
                  demo@edmradio.me
                </OuterLink>
              </FlexColumn>

              <FlexColumn>
                <span>For legal claims</span>
                <OuterLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='mailto:legal@edmradio.me'
                >
                  legal@edmradio.me
                </OuterLink>
              </FlexColumn>

              <FlexColumn>
                <OuterLink
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  href='https://instagram.com/edmradio_me?igshid=kmoz72czaon9'
                >
                  <SInstagramIcon />
                  Instagram
                </OuterLink>

                <OuterLink
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  href='https://m.facebook.com/edmradiolive/'
                >
                  <SFacebookIcon />
                  Facebook
                </OuterLink>
              </FlexColumn>
            </CopyRight>
          </Center>
        </PaddingFooter>
      </DesktopTabletFooter>

      <MobileFooter>
        <FooterLogo />

        <LinkColumn>
          <SNavLink exact to='/mobile-app'>
            Mobile app
          </SNavLink>
          <SNavLink exact to='/rising-star'>
            Rising star
          </SNavLink>

          <SNavLink exact activeClassName='active-link' to='/dj-panel'>
            DJ Panel
          </SNavLink>

          <SNavLink exact activeClassName='active-link' to='/about'>
            About
          </SNavLink>

          <SNavLink exact activeClassName='active-link' to='/privacy'>
            Privacy Policy
          </SNavLink>

          <SNavLink
            exact
            activeClassName='active-link'
            to='/terms-and-conditions'
          >
            Terms &#38; Conditions
          </SNavLink>

          <SNavLink exact activeClassName='active-link' to='/contact-us'>
            Contact Us
          </SNavLink>
          <RadioLoginBtn
            target='_blank'
            rel='noopener noreferrer'
            href='https://dj.edmradio.me/'
          >
            Radio Login
          </RadioLoginBtn>
        </LinkColumn>

        <EmailsGridColumn>
          <FlexColumn>
            <span>Send your demos to</span>
            <OuterLink
              target='_blank'
              rel='noopener noreferrer'
              href='mailto:demo@edmradio.me'
            >
              demo@edmradio.me
            </OuterLink>
          </FlexColumn>

          <FlexColumn>
            <span>For legal claims</span>
            <OuterLink
              target='_blank'
              rel='noopener noreferrer'
              href='mailto:legal@edmradio.me'
            >
              legal@edmradio.me
            </OuterLink>
          </FlexColumn>

          <FlexColumn>
            <OuterLink
              target='_blank'
              rel='noopener noreferrer'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              href='https://instagram.com/edmradio_me?igshid=kmoz72czaon9'
            >
              <SInstagramIcon />
              Instagram
            </OuterLink>

            <OuterLink
              target='_blank'
              rel='noopener noreferrer'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              href='https://m.facebook.com/edmradiolive/'
            >
              <SFacebookIcon />
              Facebook
            </OuterLink>
          </FlexColumn>
        </EmailsGridColumn>
      </MobileFooter>
    </Container>
  );
};

export default CommonLayout;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;

  .active-link {
    color: #0058dc;
  }
`;

const Padding = styled.div`
  padding: 0px 120px;
  background: #fff;
  @media (max-width: 1170px) {
    padding: 0px 80px;
  }

  @media (max-width: 600px) {
    padding: 0px 16px;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 150;
`;

const DesktopTabletFooter = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`;

const MobileFooter = styled.div`
  display: none;
  grid-row-gap: 40px;
  @media (max-width: 700px) {
    background: url(${MobileBg});
    background-repeat: no-repeat;
    background-position: right -47px bottom -49px;
    display: grid;
    padding: 40px 16px;
  }
`;

const PaddingFooter = styled.div`
  padding: 0px 120px;

  &:first-child {
    border-bottom: 1px solid #ececec;
  }

  @media (max-width: 1170px) {
    padding: 0px 80px;
  }
`;

const Header = styled.header`
  position: relative;
  ${flex.spaceBetween};
  height: 80px;

  @media (max-width: 500px) {
    height: 64px;
  }
`;

const NavLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 48px;
  align-items: center;
  transition: 0.2s ease-in-out;

  @media (max-width: 1170px) {
    display: none;
  }
`;

const SNavLink = styled(NavLink)`
  font-weight: 600;
  color: #1b1b1b;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #0058dc;
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Link = styled.a`
  ${flex.center};
  width: 153px;
  height: 48px;
  color: #0058dc;
  background: #e0e9f6;
  border-radius: 4px;
  color: #0058dc;
  font-weight: 600;
  margin-left: 48px;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: #0058dc;
    color: #fff;
  }

  @media (max-width: 1170px) {
    margin-left: 0px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileLinkWrapper = styled.div`
  padding: 16px;
  display: none;
  flex-grow: 1;
  @media (max-width: 600px) {
    display: flex;
    align-items: flex-end;
  }
`;

const RadioLoginBtn = styled.a`
  ${flex.center};
  width: 153px;
  height: 48px;
  background: #e0e9f6;
  border-radius: 4px;
  color: #0058dc;
  font-weight: 600;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: #0058dc;
    color: #fff;
  }
`;

const MobileRadioLogin = styled(RadioLoginBtn)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FooterLinks = styled.div`
  display: grid;
  padding: 44px 0px 36px 0px;
  grid-template-columns: 2fr 1fr 1fr 1fr 150px;
  height: 168px;

  @media (max-width: 1170px) {
    grid-template-columns: 1fr auto auto auto 150px;
    grid-column-gap: 48px;
  }
`;

const CopyRight = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 150px;
  align-items: center;
  height: 112px;
  font-weight: 500;

  @media (max-width: 1170px) {
    grid-template-columns: 1fr auto auto 150px;
    grid-column-gap: 56px;
  }
`;

const LinkColumn = styled.div`
  display: grid;
  grid-row-gap: 16px;
  font-weight: 600;
  color: #333333;
`;

const EmailsGridColumn = styled.div`
  display: grid;
  grid-row-gap: 24px;
  font-weight: 600;
  padding-top: 24px;
`;

const SFacebookIcon = styled(FacebookIcon)`
  margin-right: 5px;
`;

const SInstagramIcon = styled(InstagramIcon)`
  margin-right: 5px;
`;

const FlexCenter = styled.div`
  ${flex.alignCenter};
`;

const BurgerMenuWrapper = styled.div`
  display: none;

  cursor: pointer;
  @media (max-width: 1170px) {
    display: block;
    margin-left: 40px;
  }
`;

const Menu = styled.ul`
  display: none;
  z-index: 100;
  position: absolute;
  overflow: hidden;
  height: ${(p) => (p.isOpen ? '600px' : '0px')};
  left: 0px;
  right: 0px;
  top: 68px;
  flex-direction: column;
  background: #fff;
  transition: 0.5s all cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media (max-width: 1170px) {
    display: flex;
  }

  .active-menu-link {
    background: #0058dc;
    color: #fff;
  }

  .active-menu-link:before {
    display: block;
  }

  @media (max-width: 600px) {
    height: ${(p) => (p.isOpen ? `${p.fullScreenHeight}px` : '0px')};
  }

  @media (max-width: 600px) {
    top: 64px;
  }
`;

const MenuLink = styled(NavLink)`
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1b1b1b;
  flex-shrink: 0;
  height: 120px;
  background: #fff;
  ${flex.alignCenter};
  padding-left: 80px;

  &:before {
    position: absolute;
    display: none;
    content: '';
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
  }

  @media (max-width: 600px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 72px;
    padding-left: 16px;
  }
`;

const Empty = styled.div`
  display: block;

  @media (max-width: 1170px) {
    display: none;
  }
`;

const OuterLink = styled.a`
  font-weight: 600;
  color: #0058dc;

  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
    color: #0058dc;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${OuterLink} {
    &:first-child {
      margin-bottom: 16px;
    }
  }
`;
