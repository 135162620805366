import React, { useEffect } from 'react';
import styled from 'styled-components';
import { flex } from '@utils';

const Modal = ({ isVisible, close, children }) => {
  useEffect(() => {
    if (isVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = null;
  }, [isVisible]);

  return (
    isVisible && (
      <Backdrop onClick={close}>
        <Wrapper onClick={e => e.stopPropagation()}>{children}</Wrapper>
      </Backdrop>
    )
  );
};

export default Modal;

const Wrapper = styled.div`
  max-width: 95%;
  max-height: 95%;
  overflow: auto;
`;

const Backdrop = styled.div`
  ${flex.center}
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.5);
`;
