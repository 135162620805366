import React from 'react';
import styled from 'styled-components';

const ErrorMessage = ({ errors, touched, name }) =>
  errors[name] && touched[name] ? <Container>{errors[name]}</Container> : null;

export default ErrorMessage;

const Container = styled.span`
  color: #ed4545;
`;
